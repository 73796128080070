.SearchPosi{
    display: flex;
    margin-left: auto;
}

.InnerForm{
    display: flex;
    align-items: center;
    justify-content: flex-end;      
}

.inputPosit{
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-top: 5px;
    width: 90%;
    font-size: 10pt ;

}   

.inputy{
    background-color: rgba(255, 255, 255, 0.18) !important;
    border-radius: 30px !important;
    height: 30px !important;
    color: #FFF;
    padding-left: 10px !important;
    font-size: 10pt !important;
}