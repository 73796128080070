.body-info{
    display: flex;
    flex-direction: column;
    /* height: 80vh; */
    /* padding: 10px; */
}
.col-cards{
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    /* margin-top: 1rem !important;
    padding: 10px;
    width: 100%;
    margin: 0px 20px; */
}
.card-content{
    display: flex;
    flex-direction: row;
}
.card-link {
    display: flex;
    flex: 1;
    /* flex-direction: row; */
}
.card-info{
    display: flex;
    flex:1;
    flex-wrap: wrap;
}
.card-info div{
    display: flex;
    /* flex: 1; */
    flex-wrap: wrap;
    /* align-items: center; */
}
.card-info span{
    font-size: 10pt !important;
    line-height: 16px !important;
    margin-right: 10px;
}
.cardBorder{
    border: #000 1px solid;
}
.status-info{
    display: flex;
    justify-content: flex-end;
    align-self: center;
    align-items: center;
    flex-direction: column;
}
/* .num{
    position: absolute;
    z-index: 5000;
    margin-top: -41px; 
    color: rgb(86, 161, 117);
} */
.icon-ponto{
    align-self: center;
    color:#000;
}
.badge-btn{
    border-radius: 10px;
    margin-left: 0px !important;
    background: rgb(149, 189, 116);
    color: #000;
}
/* .badge-n{
    align-self: flex-end;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(69, 94, 48);
    color: rgb(35, 43, 29);
    border-radius: 20px;
    padding: 0px 6px;
    margin-bottom: 5px;
} */
.case1{
    border-color:rgb(255,87,34);
    background: rgb(255,87,34);
    color:white !important;
}
.case2{
    border-color:#ffe87c;
    background: #ffe87c;
    color:black !important;
}
.case3{
    border-color:#ffe87c;
    background: #ffe87c;
    color: black !important;
}
.case4{
    border-color:rgb(63,81,181);
    background: rgb(63,81,181);
    color:white !important;
}
.case5{
    border-color:rgb(63,81,181);
    background: rgb(63,81,181);
    color:white !important;
}
.case6{
    border-color:rgba(235, 61, 61, 0.966);
    background: rgba(235, 61, 61, 0.966);
    color: white !important;
}
.case7{
    border-color:#27C24C;
    background: #27C24C;
    color:white !important;
}
.case8{
    border-color:black;
    background: black;
    color:white !important;
}
.case9{
    border-color:#60005f;
    background: #60005f;
    color:white !important;
}
.case10{
    border-color:maroon;
    background: maroon;
    color:white !important;
}
.borderCase7{
    border: solid 1px #27C24C;
}