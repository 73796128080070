.containe{
    /*display: flex;*/
    /*height:100vh;*/
    font-family: sans-serif;
    box-sizing: border-box;
    /*justify-content: flex-start;*/ 
    margin: 0px auto;
}
.topo{
  /*width: 110%;*/
}

.corpo{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column
}
.corpo-inner{
    margin: 5rem 2rem;
    padding-top: 10px;
}
.titulo{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    padding: 5px;
    margin-bottom: 15px;
    font-size: 15pt;
}
.local{
    margin-bottom: 50px;
    font-size: 16pt;
}
.opcoes{
    display: flex;
    flex: 0.9;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 40px;
    padding: 0px 5px 20px;
}
.input-consult{
    margin-top: 3rem !important;
}
.botao-detail{
    margin-bottom: 10px;
}
.botao{
   display: flex;
   width: 35%;
   align-self: flex-end;
   background: #26a69a !important;
   /* color:#000 !important ; */
}
.info span{
    font-size: 12pt;
}


/* ajuste local do materialize de checkin */
.paddingCardAjuste{
    padding: 0 !important;
}

.ajusteMarginBottom{
    margin-bottom: 0 !important;
}