.container{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height:100vh;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-family: sans-serif;
    box-sizing: border-box;
}

.logo-cont{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    /*margin-bottom: 40px;*/
}
.logo-login{
    height:65px;
    width: 70px;
    padding: 5px;
    margin-bottom: 10px;
}
.logo-cont span{
    font-size: 18pt;
}

.form{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 80%;
    box-sizing: border-box;
    padding:10px;
}

.input{
    height: 46px;
    margin-bottom: 15px;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #00acc1;
    font-size: 1em;
    outline: none;
}

.button{
    display: -webkit-flex;
    display: flex;
    -webkit-flex:1 1;
            flex:1 1;
    width: 100%;
    color: #fff !important;  
    background-color: rgb(47, 120, 177)!important;
    /* background-color: rgb(130, 187, 148) !important; */
    outline:none;
}

.containerInput{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width:100%;
    height: 70px;
}
.containerCheck{
    display: -webkit-flex;
    display: flex;
    width: 100%;
    padding-top: 10px;
    margin-bottom: 15px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}
/*.login-check{
    background-color: rgb(154, 196, 167)
}*/

.span{
    font-size: 10pt;
    position: absolute;
    margin-top: -8px;
    background: #fff;
    padding: 0px 5px 0px 5px;
    margin-left: 10px;
    color: gray;
}

.lineButtons{
    display: -webkit-flex;
    display: flex;
    /*flex-direction: row;*/
    /* align-items: center; */
    /* align-self: flex-end; */
    /* padding: 10px; */
    width: 100%;
}

.select-empresas{
    width:100%;
}
.containe{
    /*display: flex;*/
    /*height:100vh;*/
    font-family: sans-serif;
    box-sizing: border-box;
    /*justify-content: flex-start;*/ 
    margin: 0px auto;
}
.topo{
  /*width: 110%;*/
}

.corpo{
    display: -webkit-flex;
    display: flex;
    height: 100%;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column
}
.corpo-inner{
    margin: 5rem 2rem;
    padding-top: 10px;
}
.titulo{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-align-content: flex-start;
            align-content: flex-start;
    padding: 5px;
    margin-bottom: 15px;
    font-size: 15pt;
}
.local{
    margin-bottom: 50px;
    font-size: 16pt;
}
.opcoes{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 0.9 1;
            flex: 0.9 1;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-top: 40px;
    padding: 0px 5px 20px;
}
.input-consult{
    margin-top: 3rem !important;
}
.botao-detail{
    margin-bottom: 10px;
}
.botao{
   display: -webkit-flex;
   display: flex;
   width: 35%;
   -webkit-align-self: flex-end;
           align-self: flex-end;
   background: #26a69a !important;
   /* color:#000 !important ; */
}
.info span{
    font-size: 12pt;
}


/* ajuste local do materialize de checkin */
.paddingCardAjuste{
    padding: 0 !important;
}

.ajusteMarginBottom{
    margin-bottom: 0 !important;
}
.SearchPosi{
    display: -webkit-flex;
    display: flex;
    margin-left: auto;
}

.InnerForm{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;      
}

.inputPosit{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 10px;
    padding-top: 5px;
    width: 90%;
    font-size: 10pt ;

}   

.inputy{
    background-color: rgba(255, 255, 255, 0.18) !important;
    border-radius: 30px !important;
    height: 30px !important;
    color: #FFF;
    padding-left: 10px !important;
    font-size: 10pt !important;
}
.top-dash{
  display: -webkit-flex;
  display: flex;
  -webkit-flex:1 1;
          flex:1 1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.top-dash-inner{
  display: -webkit-flex;
  display: flex;
  height: 20vh;
  width: 100%;
  margin-bottom: 20px !important;
}
.row-top{
  display: -webkit-flex;
  display: flex;
  -webkit-flex:1 1;
          flex:1 1;
  margin-bottom: 0px !important;
  width: 100%; 
  max-height: 100%;
}
.col-top{
  padding: 0px !important;
}
.card-panel-top{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0px !important;
  padding: 10px !important;
}
.top-dash-info{
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  padding-bottom: 10px;
}
.ft-dash-cont{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 5px 5px;
}
.foto-dash{
  width: 30px;
  height: 30px;
}
.top-dash-info-inner{
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 5px;
}
.consultorio{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.consultorio-num{
  border:solid 1px transparent;
  border-radius: 20px;
  padding: 8px;
  background-color:rgb(41, 121, 182)!important;
  color:white;
}
.top-dash-infoBottom a{
  display: -webkit-flex;
  display: flex;
  -webkit-flex:1 1;
          flex:1 1;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}
.top-dash-infoBottom span{
  display: -webkit-flex;
  display: flex;
  font-size: 12pt;
  font-weight: bolder /*300px*/;
}

/*inicio boby(lista) css*/
.body-dash{
  display: -webkit-flex;
  display: flex;
  -webkit-flex:3 1;
          flex:3 1;
  -webkit-justify-content: center;
          justify-content: center;
  max-height: 100%;
}
.body-dash-inner{
  /* display: flex; */
  margin-top: 10px !important;
  /* justify-content: flex-start; */
  width: 100%;
  /* height: 25vh; */
}
.lista-body{
  display: -webkit-flex;
  display: flex;
  width: 100%;
}
.body-dash-collection{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
}
.body-dash-collection a{
  color: rgb(58, 58, 58) !important;
  font-size: 12pt;
}

.inner{
  display: -webkit-flex;
  display: flex;
  -webkit-flex:1 1;
          flex:1 1;
}
.status-info{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-flex-direction: column;
          flex-direction: column;
}

/*modal 2*/
.drop-infos-dash{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.select-option-dash{
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 0px 10px;
}
.plano-col{
  display: -webkit-flex;
  display: flex;
  margin-right: 5px;
}
.espec-col{
  display: -webkit-flex;
  display: flex;
  margin-left: 5px;
}
.data-dash-cont{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.data-dash-cont span{
  font-size: 16pt;
  font-weight: 400;
}
.select-option option{
  color: black !important;
}
.colorMaior{
    color: #B22222 !important
}
.colorMenor{
    color: rgb(11, 55, 151) !important;
}


.reservado-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex:1 1;
            flex:1 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 10px 0px 2px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(59, 136, 130, 0.14), 0 2px 20px 0 rgba(27, 52, 58, 0.2);
    max-width: 100% !important; 
}
.calendario-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: row;
            flex-direction: row;
    /*padding-top: 10px;*/
}
.calendario{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    max-width: 100% !important;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}
.calendar-table{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: center;
            justify-content: center;
}
.data-table{
    display: -webkit-flex;
    display: flex;
    -webkit-flex:1 1;
            flex:1 1;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.data-table:hover{
    background: rgba(189, 186, 186, 0.863);
    border-radius: 15px;
    transition: .5s ease-in-out all;
}
.dia{
    display: -webkit-flex;
    display: flex;
    font-size: 14pt;
    -webkit-justify-content: center;
            justify-content: center;
}
.dia-atual/*:hover*/ {
    border-radius: 20px;
    background:rgb(67, 156, 197);
    color: rgb(235, 231, 231);
    transition:all 0.5s ease;
    transition: .5s cubic-bezier(.39,.58,.57,1);
}
.semana{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-size:10pt;
    /* border-bottom: 1px dashed #000 */
}
.btn-comando{
    width: 30px;
    -webkit-align-items: center;
            align-items: center;
    max-width: 100% !important;
}
.btn-comando i{
    margin:0px !important;
}
.num-agend-content{
    display: -webkit-flex;
    display: flex; 
    -webkit-align-content: center; 
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.num-agend{
    display: -webkit-flex;
    display: flex;
    height: 60px;
    width: 40px;
    margin: 0px 10px;
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.data-display{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    padding: 2px 0px;
    font-size: 12pt;
    font-family: 'Nunito', sans-serif;
}

  
.tab-demo{
    height: 40vh;
    background: #fff;
}
.tab-switch {
    display: -webkit-flex;
    display: flex;
    -webkit-flex:1 1;
            flex:1 1;
}
.tab-col-avalia{
    -webkit-flex:1 1;
            flex:1 1;
}
.tab-col-trata {
    -webkit-flex:1 1;
            flex:1 1;
}

/*cards de avaliação*/
.col-cards{
    max-width: 100% !important; 
}
.card{
    max-width: 100% !important;
}
.content-avalia{
    -webkit-flex-direction: row;
            flex-direction: row;
    margin: 10px;
}
.info-avalia{
    -webkit-flex:1 1;
            flex:1 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    padding-right: 5px;
    font-size: 10pt;
}
.icons-card-avalia{
    -webkit-align-self: center;
            align-self: center;    
}


/*cards de tratamentos*/
.card-trata{
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: column;
            flex-direction: column;
}


.check-btn{
    display: -webkit-flex;
    display: flex;
    width: 20%;
    padding-left: 6px;
    -webkit-align-items: center;
            align-items: center;
}
.btn-check-inner{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    /*background-color: rgb(112, 161, 72) !important;*/
}
.card-trata{
    width: 87%;
    word-wrap: break-word;
}

/* lista de agrupamentos */
.body-dash{
    display: -webkit-flex;
    display: flex;
    -webkit-flex:3 1;
            flex:3 1;
    -webkit-justify-content: center;
            justify-content: center;
    max-height: 100%;
  }
  .body-dash-inner{
    /* display: flex; */
    margin-top: 10px !important;
    /* justify-content: flex-start; */
    width: 100%;
    /* height: 25vh; */
  }
  .lista-body{
    display: -webkit-flex;
    display: flex;
    width: 100%;
  }
  .body-dash-collection{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;
  }
  .body-dash-collection a{
    color: rgb(58, 58, 58) !important;
    font-size: 12pt;
  }

  .line-autocomplete{
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: center;
              justify-content: center;
  }

  .row-checkbox{
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: space-between;
              justify-content: space-between;
  }
/*cores
    logo rgb(42, 128, 192)
    logo rgb(154, 196, 167)
    nav #001827 
    dia atual rgb(67, 156, 197)
    variação de azul rgba(77, 163, 201)
*/

.conteudo{
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* flex:1; */
    /* max-height: 100vh !important; */
} 


.header-modal{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.modal h4{
    text-align: center;
    font-size: 14pt;
    margin: 5px 0px;
    padding-top: 10px;
}
.header-modal h4{
    font-family: "Montserrat", sans-serif;
}


.input-left{
    padding-right: 25px !important;
}


.card-title{
    font-weight: 300;
}
.card-info{
    font-size: 10pt !important;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}

/*cor de icones dentro de links*/
.icones-a{
    color: #000;
}

.row-display{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.column-display{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

/*cor das palavras do dorpdow*/
.drops-info li a{
    color:rgb(42, 128, 192) !important;
    font-weight: 400;
}
/* FIM cor das palavras do dorpdow*/

/*inicio barra de navegação*//*inicio barra de navegação*/
.nav{
    background-color: #001827 !important;
}
.infjob{
    font-size: 16pt;
}
.nav-wrapper{
    display:-webkit-flex;
    display:flex; 
    -webkit-align-items:center; 
            align-items:center;
    width: 100%;
}
.nav-brand{
    width: 100x;
    height: 30px;
}  
/*icone menu*/
.nav .sidenav-trigger{
    margin: 0 5px;
}
.back-nav{
    /*background: rgba(77, 163, 201);*/
    background:rgb(154, 196, 167);
}
/*fim navBar*//*fim navBar*//*fim navBar*//*fim navBar*/



/*cards de comissão tela repasses*/
.row-comissao{
    display: -webkit-flex;
    display: flex;
    width: 100%;
    max-height: 100%;
    margin-bottom: 0px !important;
}
.col-comissao{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
}
.card-panel-comissao{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 15px !important;
}
.valor-comissao{
    border-radius: 15px;
    margin:3px 0px;
    padding: 0px 5px;
    color: #fff;
}
.blueBack{
    background: rgb(42, 128, 192);
}
.redBack{
    background: rgb(207, 39, 39);
}
.greenBack{
    background: rgb(57, 168, 92);
}
.yelouBack{
    background: #f0ad4e;
    color:rgb(29, 28, 28);
}

/*fim modal*/



/*itens avaliação*/
.lista-avalia{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 10px;
    margin: 10px 0px; 
}
.itens-lista-avalia{
    display: -webkit-flex;
    display: flex;
    width: 50%;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.itens-lista {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.itens-lista span{
    margin-right: 15px;
}
/* fim itens avaliação*/

.body-info{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* height: 80vh; */
    /* padding: 10px; */
}
.col-cards{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 60px;
    /* margin-top: 1rem !important;
    padding: 10px;
    width: 100%;
    margin: 0px 20px; */
}
.card-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.card-link {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    /* flex-direction: row; */
}
.card-info{
    display: -webkit-flex;
    display: flex;
    -webkit-flex:1 1;
            flex:1 1;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.card-info div{
    display: -webkit-flex;
    display: flex;
    /* flex: 1; */
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    /* align-items: center; */
}
.card-info span{
    font-size: 10pt !important;
    line-height: 16px !important;
    margin-right: 10px;
}
.cardBorder{
    border: #000 1px solid;
}
.status-info{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-self: center;
            align-self: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
/* .num{
    position: absolute;
    z-index: 5000;
    margin-top: -41px; 
    color: rgb(86, 161, 117);
} */
.icon-ponto{
    -webkit-align-self: center;
            align-self: center;
    color:#000;
}
.badge-btn{
    border-radius: 10px;
    margin-left: 0px !important;
    background: rgb(149, 189, 116);
    color: #000;
}
/* .badge-n{
    align-self: flex-end;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(69, 94, 48);
    color: rgb(35, 43, 29);
    border-radius: 20px;
    padding: 0px 6px;
    margin-bottom: 5px;
} */
.case1{
    border-color:rgb(255,87,34);
    background: rgb(255,87,34);
    color:white !important;
}
.case2{
    border-color:#ffe87c;
    background: #ffe87c;
    color:black !important;
}
.case3{
    border-color:#ffe87c;
    background: #ffe87c;
    color: black !important;
}
.case4{
    border-color:rgb(63,81,181);
    background: rgb(63,81,181);
    color:white !important;
}
.case5{
    border-color:rgb(63,81,181);
    background: rgb(63,81,181);
    color:white !important;
}
.case6{
    border-color:rgba(235, 61, 61, 0.966);
    background: rgba(235, 61, 61, 0.966);
    color: white !important;
}
.case7{
    border-color:#27C24C;
    background: #27C24C;
    color:white !important;
}
.case8{
    border-color:black;
    background: black;
    color:white !important;
}
.case9{
    border-color:#60005f;
    background: #60005f;
    color:white !important;
}
.case10{
    border-color:maroon;
    background: maroon;
    color:white !important;
}
.borderCase7{
    border: solid 1px #27C24C;
}
.flex-info{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}
.btn-adi{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: center;
            justify-content: center;
}
.add-btn{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: center;
            justify-content: center;
    color:rgb(42, 128, 192) !important;
}
.more-info-btn{
    padding-bottom: 20px;
}
#icon-button-file{
    display: none
}

#img-source{
    max-width:100%;
    height: 100px
}


.content-trata{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.envia-foto-tratamento{
    display: -webkit-flex;
    display: flex;
    padding: 10px;
    /* margin-top: 5px; */
    -webkit-justify-content: /* flex-end */ center;
            justify-content: /* flex-end */ center;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    
}

.button-photo{
    background-color: unset /* #225c80 */ !important; 
    border: 1px solid  #225c80 !important;
    border-radius: 5px !important;
}
.button-photo i{
    color:  #225c80;
}

.button-photo-send{
    background-color: #225c80 !important;
    border-radius: 5px !important;
    margin-left: 5px;
}

/*info paciente*/

.top-modal{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    width: 80vw;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.doct-info{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}
.foto-perfil{
    padding: 10px;
    width: 60px;
    height: 60px;
}
.span-info{  
    -webkit-flex-direction: column;  
            flex-direction: column;  
    -webkit-justify-content: flex-start;  
            justify-content: flex-start;
}
.botao-detail{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 0px !important;
}
.more-btn{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    /*color:rgb(42, 128, 192) !important;*/
}

.more-info-btn{
    padding-bottom: 0px !important;
    padding-left:50px; 
    /*color: rgb(46, 141, 141) !important;*/
}





/*modalLancarAval (modal lançar avaliação)*/
.span-info-edital{
    display: -webkit-flex;
    display: flex;
    -webkit-flex:1 1;
            flex:1 1;
    -webkit-flex-direction: row;
            flex-direction: row
}
.xis-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    font-size: 30px;
    padding-right: 20px;
}
.face-dente-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
}
.autocomplete{
    margin-bottom: 0px !important;
}
.valor-add-btn{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.valor-edital{
    font-size: 12pt;
    font-weight: 400;
    margin-left: 15px;
    border-bottom: solid 1px rgb(154, 196, 167);
}
.input-avalia{
    padding: 0 0.75rem !important;
    margin-bottom: 0px !important;
}
.input-left-left{
    padding-right: 38px;
}


.footer-itens{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}
.btn-check-inner-info{
    margin-right: 10px !important;
}
/*Fim modalLancarAval (modal lançar avaliação)*/
.header-title{
    margin: 10px 0px;
    /* padding: 10px; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.header-title h5{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    /* justify-content: flex-start; */
    font-size: 16pt;
}

.width-close{
    max-width: 50px;
}
.top-repass{
    display: -webkit-flex;
    display: flex;
    padding: 10px 0px 15px 0px;
}
.top-repass-inner{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    width: 100%;
    /*padding:10px 0px!important;*/
}
.row-repass{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    margin: 0px !important;
}
.col-repass{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: row;
            flex-direction: row;
}
.card-repass{
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0px !important;
    padding: 10px !important;
}
.info-repass{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}



.bottom-repass{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;
    padding-top: 10px;
}


/*component CardRepasses*/
.card-comissao-total{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;    
    -webkit-align-items: center;    
            align-items: center;
    margin-top: 12px;
}
.card-comissao-total span{
    margin: 0px 5px;
}
 /*modal*/
.modal{
    max-height: none !important;
    width: 100% !important;
    /* padding-top: 10vh; */
}
.bottom-sheet{
    max-height: none !important;
    height: 100vh !important;
    -webkit-overflow-scrolling: touch;
}
.modal-content{
    padding: 10px !important;
}
.row .col{
    padding: 0px !important;
}
/* fim modal */


input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]){
    border-bottom: 1px solid rgb(154, 196, 167) !important;
    box-shadow: 0 1px 0 0 rgb(154, 196, 167) !important;
}
input:not([type]):focus:not([readonly])+label, input[type=text]:not(.browser-default):focus:not([readonly])+label, input[type=password]:not(.browser-default):focus:not([readonly])+label, input[type=email]:not(.browser-default):focus:not([readonly])+label, input[type=url]:not(.browser-default):focus:not([readonly])+label, input[type=time]:not(.browser-default):focus:not([readonly])+label, input[type=date]:not(.browser-default):focus:not([readonly])+label, input[type=datetime]:not(.browser-default):focus:not([readonly])+label, input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label, input[type=tel]:not(.browser-default):focus:not([readonly])+label, input[type=number]:not(.browser-default):focus:not([readonly])+label, input[type=search]:not(.browser-default):focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label{
    color:rgb(72, 136, 92) !important;

}
/* check box color */

[type="checkbox"].filled-in:checked+span:not(.lever):after{
    border-right: 2px solid rgb(37, 116, 175) !important;
    border-bottom: 2px solid rgb(37, 116, 175) !important;
    border: 2px solid rgb(37, 116, 175);
    background-color: rgb(37, 116, 175);
}
[type="checkbox"]:checked+span:not(.lever):before {
     border-right: 2px solid rgb(42, 128, 192); 
    border-bottom: 2px solid rgb(42, 128, 192);
}
/* fim check box color */


/*ajustes da tabela tela home*/
.tabs .tab a:hover, .tabs .tab a.active{
    color: black !important;
}
.tabs .indicator{
    background-color: rgb(42, 128, 192) !important;
}
.tabs .tab a{
    color: gray;
}

/*cards tela home*/
.teal {
    background-color: #fff !important;
}

.card .card-content{
    padding: 10px !important;
}


/*botão e navegação*/
.btn-menu{
    background-color:#011e30 !important;
} 
.btn-floating{
    background-color: #225c80;
}
.btn-floating i{
    color:#fff;
}

/*cor das opções do select*/
.dropdown-content li>span{
    color:rgb(42, 128, 192);
}
/*bordar do select*/
.select-wrapper input.select-dropdown:focus{
    border-bottom:solid 1px rgb(154, 196, 167);
 }


