 /*modal*/
.modal{
    max-height: none !important;
    width: 100% !important;
    /* padding-top: 10vh; */
}
.bottom-sheet{
    max-height: none !important;
    height: 100vh !important;
    -webkit-overflow-scrolling: touch;
}
.modal-content{
    padding: 10px !important;
}
.row .col{
    padding: 0px !important;
}
/* fim modal */


input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]){
    border-bottom: 1px solid rgb(154, 196, 167) !important;
    box-shadow: 0 1px 0 0 rgb(154, 196, 167) !important;
}
input:not([type]):focus:not([readonly])+label, input[type=text]:not(.browser-default):focus:not([readonly])+label, input[type=password]:not(.browser-default):focus:not([readonly])+label, input[type=email]:not(.browser-default):focus:not([readonly])+label, input[type=url]:not(.browser-default):focus:not([readonly])+label, input[type=time]:not(.browser-default):focus:not([readonly])+label, input[type=date]:not(.browser-default):focus:not([readonly])+label, input[type=datetime]:not(.browser-default):focus:not([readonly])+label, input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label, input[type=tel]:not(.browser-default):focus:not([readonly])+label, input[type=number]:not(.browser-default):focus:not([readonly])+label, input[type=search]:not(.browser-default):focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label{
    color:rgb(72, 136, 92) !important;

}
/* check box color */

[type="checkbox"].filled-in:checked+span:not(.lever):after{
    border-right: 2px solid rgb(37, 116, 175) !important;
    border-bottom: 2px solid rgb(37, 116, 175) !important;
    border: 2px solid rgb(37, 116, 175);
    background-color: rgb(37, 116, 175);
}
[type="checkbox"]:checked+span:not(.lever):before {
     border-right: 2px solid rgb(42, 128, 192); 
    border-bottom: 2px solid rgb(42, 128, 192);
}
/* fim check box color */


/*ajustes da tabela tela home*/
.tabs .tab a:hover, .tabs .tab a.active{
    color: black !important;
}
.tabs .indicator{
    background-color: rgb(42, 128, 192) !important;
}
.tabs .tab a{
    color: gray;
}

/*cards tela home*/
.teal {
    background-color: #fff !important;
}

.card .card-content{
    padding: 10px !important;
}


/*botão e navegação*/
.btn-menu{
    background-color:#011e30 !important;
} 
.btn-floating{
    background-color: #225c80;
}
.btn-floating i{
    color:#fff;
}

/*cor das opções do select*/
.dropdown-content li>span{
    color:rgb(42, 128, 192);
}
/*bordar do select*/
.select-wrapper input.select-dropdown:focus{
    border-bottom:solid 1px rgb(154, 196, 167);
 }

