.container{
    display: flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    flex-direction: column;
    font-family: sans-serif;
    box-sizing: border-box;
}

.logo-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*margin-bottom: 40px;*/
}
.logo-login{
    height:65px;
    width: 70px;
    padding: 5px;
    margin-bottom: 10px;
}
.logo-cont span{
    font-size: 18pt;
}

.form{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    width: 80%;
    box-sizing: border-box;
    padding:10px;
}

.input{
    height: 46px;
    margin-bottom: 15px;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #00acc1;
    font-size: 1em;
    outline: none;
}

.button{
    display: flex;
    flex:1;
    width: 100%;
    color: #fff !important;  
    background-color: rgb(47, 120, 177)!important;
    /* background-color: rgb(130, 187, 148) !important; */
    outline:none;
}

.containerInput{
    display: flex;
    flex-direction: column;
    width:100%;
    height: 70px;
}
.containerCheck{
    display: flex;
    width: 100%;
    padding-top: 10px;
    margin-bottom: 15px;
    justify-content: flex-start;
}
/*.login-check{
    background-color: rgb(154, 196, 167)
}*/

.span{
    font-size: 10pt;
    position: absolute;
    margin-top: -8px;
    background: #fff;
    padding: 0px 5px 0px 5px;
    margin-left: 10px;
    color: gray;
}

.lineButtons{
    display: flex;
    /*flex-direction: row;*/
    /* align-items: center; */
    /* align-self: flex-end; */
    /* padding: 10px; */
    width: 100%;
}

.select-empresas{
    width:100%;
}