.header-title{
    margin: 10px 0px;
    /* padding: 10px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.header-title h5{
    display: flex;
    flex: 1;
    /* justify-content: flex-start; */
    font-size: 16pt;
}

.width-close{
    max-width: 50px;
}