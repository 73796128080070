/*info paciente*/

.top-modal{
    display: flex;
    flex: 1;
    width: 80vw;
    flex-direction: column;
}
.doct-info{
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
}
.foto-perfil{
    padding: 10px;
    width: 60px;
    height: 60px;
}
.span-info{  
    flex-direction: column;  
    justify-content: flex-start;
}
.botao-detail{
    display: flex;
    flex: 1;
    justify-content: center;
    margin-bottom: 0px !important;
}
.more-btn{
    display: flex;
    flex: 1;
    /*color:rgb(42, 128, 192) !important;*/
}

.more-info-btn{
    padding-bottom: 0px !important;
    padding-left:50px; 
    /*color: rgb(46, 141, 141) !important;*/
}





/*modalLancarAval (modal lançar avaliação)*/
.span-info-edital{
    display: flex;
    flex:1;
    flex-direction: row
}
.xis-content{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    font-size: 30px;
    padding-right: 20px;
}
.face-dente-content{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.autocomplete{
    margin-bottom: 0px !important;
}
.valor-add-btn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.valor-edital{
    font-size: 12pt;
    font-weight: 400;
    margin-left: 15px;
    border-bottom: solid 1px rgb(154, 196, 167);
}
.input-avalia{
    padding: 0 0.75rem !important;
    margin-bottom: 0px !important;
}
.input-left-left{
    padding-right: 38px;
}


.footer-itens{
    display: flex;
    justify-content: space-between;
}
.btn-check-inner-info{
    margin-right: 10px !important;
}
/*Fim modalLancarAval (modal lançar avaliação)*/