#icon-button-file{
    display: none
}

#img-source{
    max-width:100%;
    height: 100px
}


.content-trata{
    display: flex;
    flex-direction: column;
}

.envia-foto-tratamento{
    display: flex;
    padding: 10px;
    /* margin-top: 5px; */
    justify-content: /* flex-end */ center;
    align-items: flex-end;
    
}

.button-photo{
    background-color: unset /* #225c80 */ !important; 
    border: 1px solid  #225c80 !important;
    border-radius: 5px !important;
}
.button-photo i{
    color:  #225c80;
}

.button-photo-send{
    background-color: #225c80 !important;
    border-radius: 5px !important;
    margin-left: 5px;
}
