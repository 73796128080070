.colorMaior{
    color: #B22222 !important
}
.colorMenor{
    color: rgb(11, 55, 151) !important;
}


.reservado-content{
    display: flex;
    flex:1;
    flex-direction: column;
    padding: 10px 0px 2px;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(59, 136, 130, 0.14), 0 2px 20px 0 rgba(27, 52, 58, 0.2);
    max-width: 100% !important; 
}
.calendario-content{
    display: flex;
    flex: 1;
    flex-direction: row;
    /*padding-top: 10px;*/
}
.calendario{
    display: flex;
    flex: 1;
    max-width: 100% !important;
    flex-direction: row;
    align-items: center;
}
.calendar-table{
    display: flex;
    flex: 1;
    justify-content: center;
}
.data-table{
    display: flex;
    flex:1;
    justify-content: center;
    flex-direction: column;
}
.data-table:hover{
    background: rgba(189, 186, 186, 0.863);
    border-radius: 15px;
    transition: .5s ease-in-out all;
}
.dia{
    display: flex;
    font-size: 14pt;
    justify-content: center;
}
.dia-atual/*:hover*/ {
    border-radius: 20px;
    background:rgb(67, 156, 197);
    color: rgb(235, 231, 231);
    transition:all 0.5s ease;
    transition: .5s cubic-bezier(.39,.58,.57,1);
}
.semana{
    display: flex;
    justify-content: center;
    font-size:10pt;
    /* border-bottom: 1px dashed #000 */
}
.btn-comando{
    width: 30px;
    align-items: center;
    max-width: 100% !important;
}
.btn-comando i{
    margin:0px !important;
}
.num-agend-content{
    display: flex; 
    align-content: center;
    justify-content: center;
}
.num-agend{
    display: flex;
    height: 60px;
    width: 40px;
    margin: 0px 10px;
    align-self: center;
    align-items: center;
    justify-content: center;
}
.data-display{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 2px 0px;
    font-size: 12pt;
    font-family: 'Nunito', sans-serif;
}

  
.tab-demo{
    height: 40vh;
    background: #fff;
}
.tab-switch {
    display: flex;
    flex:1 ;
}
.tab-col-avalia{
    flex:1;
}
.tab-col-trata {
    flex:1;
}

/*cards de avaliação*/
.col-cards{
    max-width: 100% !important; 
}
.card{
    max-width: 100% !important;
}
.content-avalia{
    flex-direction: row;
    margin: 10px;
}
.info-avalia{
    flex:1;
    flex-direction: column;
    align-items: center;
    padding-right: 5px;
    font-size: 10pt;
}
.icons-card-avalia{
    align-self: center;    
}


/*cards de tratamentos*/
.card-trata{
    flex: 1;
    flex-direction: column;
}


.check-btn{
    display: flex;
    width: 20%;
    padding-left: 6px;
    align-items: center;
}
.btn-check-inner{
    display: flex;
    align-items: center;
    /*background-color: rgb(112, 161, 72) !important;*/
}
.card-trata{
    width: 87%;
    word-wrap: break-word;
}

/* lista de agrupamentos */
.body-dash{
    display: flex;
    flex:3;
    justify-content: center;
    max-height: 100%;
  }
  .body-dash-inner{
    /* display: flex; */
    margin-top: 10px !important;
    /* justify-content: flex-start; */
    width: 100%;
    /* height: 25vh; */
  }
  .lista-body{
    display: flex;
    width: 100%;
  }
  .body-dash-collection{
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .body-dash-collection a{
    color: rgb(58, 58, 58) !important;
    font-size: 12pt;
  }

  .line-autocomplete{
      display: flex;
      flex-direction: row;
      justify-content: center;
  }

  .row-checkbox{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }