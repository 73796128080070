.top-dash{
  display: flex;
  flex:1;
  justify-content: center;
  align-items: center;
}
.top-dash-inner{
  display: flex;
  height: 20vh;
  width: 100%;
  margin-bottom: 20px !important;
}
.row-top{
  display: flex;
  flex:1;
  margin-bottom: 0px !important;
  width: 100%; 
  max-height: 100%;
}
.col-top{
  padding: 0px !important;
}
.card-panel-top{
  display: flex;
  flex-direction: column;
  margin: 0px !important;
  padding: 10px !important;
}
.top-dash-info{
  display: flex;
  flex: 1;
  width: 100%;
  padding-bottom: 10px;
}
.ft-dash-cont{
  display: flex;
  align-items: center;
  padding: 0 5px 5px;
}
.foto-dash{
  width: 30px;
  height: 30px;
}
.top-dash-info-inner{
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px;
}
.consultorio{
  display: flex;
  align-items: center;
  justify-content: center;
}
.consultorio-num{
  border:solid 1px transparent;
  border-radius: 20px;
  padding: 8px;
  background-color:rgb(41, 121, 182)!important;
  color:white;
}
.top-dash-infoBottom a{
  display: flex;
  flex:1;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.top-dash-infoBottom span{
  display: flex;
  font-size: 12pt;
  font-weight: bolder /*300px*/;
}

/*inicio boby(lista) css*/
.body-dash{
  display: flex;
  flex:3;
  justify-content: center;
  max-height: 100%;
}
.body-dash-inner{
  /* display: flex; */
  margin-top: 10px !important;
  /* justify-content: flex-start; */
  width: 100%;
  /* height: 25vh; */
}
.lista-body{
  display: flex;
  width: 100%;
}
.body-dash-collection{
  display: flex;
  flex-direction: column;
  flex: 1;
}
.body-dash-collection a{
  color: rgb(58, 58, 58) !important;
  font-size: 12pt;
}

.inner{
  display: flex;
  flex:1;
}
.status-info{
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

/*modal 2*/
.drop-infos-dash{
  display: flex;
  flex-direction: column;
}
.select-option-dash{
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 0px 10px;
}
.plano-col{
  display: flex;
  margin-right: 5px;
}
.espec-col{
  display: flex;
  margin-left: 5px;
}
.data-dash-cont{
  display: flex;
  justify-content: center;
}
.data-dash-cont span{
  font-size: 16pt;
  font-weight: 400;
}
.select-option option{
  color: black !important;
}