/*cores
    logo rgb(42, 128, 192)
    logo rgb(154, 196, 167)
    nav #001827 
    dia atual rgb(67, 156, 197)
    variação de azul rgba(77, 163, 201)
*/

.conteudo{
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* flex:1; */
    /* max-height: 100vh !important; */
} 


.header-modal{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal h4{
    text-align: center;
    font-size: 14pt;
    margin: 5px 0px;
    padding-top: 10px;
}
.header-modal h4{
    font-family: "Montserrat", sans-serif;
}


.input-left{
    padding-right: 25px !important;
}


.card-title{
    font-weight: 300;
}
.card-info{
    font-size: 10pt !important;
    justify-content: flex-start;
}

/*cor de icones dentro de links*/
.icones-a{
    color: #000;
}

.row-display{
    display: flex;
    flex-direction: row;
}
.column-display{
    display: flex;
    flex-direction: column;
}

/*cor das palavras do dorpdow*/
.drops-info li a{
    color:rgb(42, 128, 192) !important;
    font-weight: 400;
}
/* FIM cor das palavras do dorpdow*/

/*inicio barra de navegação*//*inicio barra de navegação*/
.nav{
    background-color: #001827 !important;
}
.infjob{
    font-size: 16pt;
}
.nav-wrapper{
    display:flex; 
    align-items:center;
    width: 100%;
}
.nav-brand{
    width: 100x;
    height: 30px;
}  
/*icone menu*/
.nav .sidenav-trigger{
    margin: 0 5px;
}
.back-nav{
    /*background: rgba(77, 163, 201);*/
    background:rgb(154, 196, 167);
}
/*fim navBar*//*fim navBar*//*fim navBar*//*fim navBar*/



/*cards de comissão tela repasses*/
.row-comissao{
    display: flex;
    width: 100%;
    max-height: 100%;
    margin-bottom: 0px !important;
}
.col-comissao{
    display: flex;
    flex: 1;
}
.card-panel-comissao{
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 15px !important;
}
.valor-comissao{
    border-radius: 15px;
    margin:3px 0px;
    padding: 0px 5px;
    color: #fff;
}
.blueBack{
    background: rgb(42, 128, 192);
}
.redBack{
    background: rgb(207, 39, 39);
}
.greenBack{
    background: rgb(57, 168, 92);
}
.yelouBack{
    background: #f0ad4e;
    color:rgb(29, 28, 28);
}

/*fim modal*/



/*itens avaliação*/
.lista-avalia{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin: 10px 0px; 
}
.itens-lista-avalia{
    display: flex;
    width: 50%;
    flex-direction: column;
}
.itens-lista {
    display: flex;
    flex-direction: row;
}
.itens-lista span{
    margin-right: 15px;
}
/* fim itens avaliação*/
