.top-repass{
    display: flex;
    padding: 10px 0px 15px 0px;
}
.top-repass-inner{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    /*padding:10px 0px!important;*/
}
.row-repass{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px !important;
}
.col-repass{
    display: flex;
    flex: 1;
    flex-direction: row;
}
.card-repass{
    display: flex;
    flex: 1;
    margin: 0px !important;
    padding: 10px !important;
}
.info-repass{
    display: flex;
    flex-direction: column;
}



.bottom-repass{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 10px;
}


/*component CardRepasses*/
.card-comissao-total{
    display: flex;
    flex-direction: row;
    justify-content: center;    
    align-items: center;
    margin-top: 12px;
}
.card-comissao-total span{
    margin: 0px 5px;
}