.flex-info{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
}
.btn-adi{
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: center;
}
.add-btn{
    display: flex;
    flex: 1;
    justify-content: center;
    color:rgb(42, 128, 192) !important;
}
.more-info-btn{
    padding-bottom: 20px;
}